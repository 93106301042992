<template>
  <div class="header">
    <span @click="jumpHome"> WIKI </span>

    <el-dropdown class="user">
      <div class="userInfo">
        <el-avatar class="avatar" :src="loginAvatarUrl" size="small" />
        <div class="nickName">{{ loginNickName }}</div>
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item @click="logOut">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
  <div class="main">
    <router-view :loginName="loginName" />
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import * as api from "../lib/home.js";
const router = useRouter();
let loginName = ref("");
let loginNickName = ref("");
let loginAvatarUrl = ref("");

onMounted(() => {
  getUserInfo();
});

const getUserInfo = () => {
  api.userInfo().then((res) => {
    if (res.result == 200) {
      loginName.value = res.loginName;
      loginAvatarUrl.value = res.avatar;
      loginNickName.value = res.userName;
    }
  });
};

const jumpHome = () => {
  router.replace("/homeContent");
};

const logOut = () => {
  api.logout().then((res) => {
    if (res.result == 200) {
      let url = window.location.href;
      url = url.split("/");
      url = url[0] + "//" + url[2];
      window.location.href = url;
    }
  });
};
</script>

<style lang="scss" scoped>
.header {
  height: 50px;
  line-height: 50px;
  padding: 0 10px;
  border-bottom: 1px solid #e8e8e8;
  font-weight: 500;
  box-sizing: border-box;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);

  span {
    cursor: pointer;
  }

  .user {
    margin-left: auto;
    margin-right: 10px;
    cursor: pointer;

    &:focus-visible {
      outline: none !important;
    }

    .userInfo {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;

      &:focus-visible {
        outline: none !important;
      }

      .nickName {
        font-size: 14px;
        margin-left: 5px;
      }
    }
  }
}
.main {
  min-height: calc(100vh - 60px);
}
</style>
